import React from "react";
import ReactDOM from "react-dom";
import 'webrtc-adapter/out/adapter_no_edge';
import "uikit";
import UIkit from "uikit";
import Icons from "uikit/dist/js/uikit-icons";
import "uikit/dist/css/uikit.css";
import App from "./App";

UIkit.use(Icons);

const rootElement = document.getElementById("root");
ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    rootElement
);
